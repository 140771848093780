<script setup lang="ts">
import {
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
} from '@headlessui/vue'

import { useMagicKeys, whenever } from '@vueuse/core'

import { computed, ref, watch } from 'vue'
import { useAdminStore } from '@voix/store/adminStore'
import VoixModal from '@voix/components/chrome/VoixModal.vue'
import CheckboxInput from '@voix/components/chrome/controls/CheckboxInput.vue'
import TextInput from '@voix/components/chrome/controls/TextInput.vue'
import ReleaseSelect from '@voix/components/chrome/pages/ReleaseSelect.vue'
import type { ReleaseInterface } from '@voix/types'

const props = defineProps({
  label: {
    type: String,
    default: 'Save',
  },
  direction: {
    type: String,
    default: 'up',
  },
  canDraft: {
    type: Boolean,
    default: false,
  },
  isDrafter: {
    type: Boolean,
    default: false,
  },
  draftRelease: {
    type: Object || null,
    default: null,
  },
})

const emit = defineEmits(['save', 'saveDraft', 'deleteDraft'])

const adminStore = useAdminStore()

const saveMode = ref('')
const releaseLabel = ref('')
const releaseDate = ref(null)
const releaseId = ref('')
const panelOpen = ref(false)

const publishImmediately = ref(true)
watch(publishImmediately, (newValue) => {
  if (newValue)
    releaseDate.value = null
})

function selectRelease(release: ReleaseInterface) {
  releaseId.value = release.id
}

function requestPublish() {
  saveMode.value = 'publish'
}

async function submitSave(close: () => void) {
  emit('save', {
    saveMode,
    releaseDate,
    releaseId,
    releaseLabel,
    endSave,
    close,
  })
}

function endSave(close: () => void) {
  close()
  saveMode.value = ''
  releaseLabel.value = ''
  releaseDate.value = null
  panelOpen.value = false
}

const isDraft = computed(() => {
  return props.draftRelease !== null
})

const allowedToSave = computed(() => {
  if (!isDraft.value)
    return true

  if (props.isDrafter)
    return true

  return false
})

function saveDraft() {
  if (props.canDraft) {
    emit('saveDraft', {
      endSave,
      close,
      deleteDraft,
    })
  }
  else {
    panelOpen.value = !panelOpen.value
  }
}

// Keyboard shortcuts
const { ctrl_s } = useMagicKeys()
whenever(ctrl_s, () => {
  saveDraft()
})

const deleteDraftWarning = ref(false)

function requestAbandonDraft() {
  deleteDraftWarning.value = true
}
function deleteDraft() {
  emit('deleteDraft')
  deleteDraftWarning.value = false
  panelOpen.value = false
}
</script>

<template>
  <div
    class="relative"
  >
    <Transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    />

    <Popover v-slot="{ open, close }" class="w-full focus:outline-none">
      <div
        class="flex justify-between font-medium "
        :class="{
          'pointer-events-none opacity-50': !allowedToSave,
        }"
      >
        <button
          class="flex justify-between items-center py-2 pl-4 pr-2 flex-1 p-2 rounded-l duration-200 voix-admin-bg-light hover:voix-admin-bg-lightest voix-admin-text-dark hover:voix-admin-text-darkest text-left"
          @click="saveDraft"
        >
          <span class="py-1">{{ label }}</span>

          <div v-show="isDraft" class="flex items-center space-x-2 border-2 border-white voix-admin-bg-lightest voix-admin-text px-2 py-0.5 rounded-full text-2xs">
            <span>Draft Mode</span>
          </div>
        </button>
        <PopoverButton
          class="p-2 rounded-r transition-colors duration-200 focus:outline-none  hover:voix-admin-bg-dark hover:text-white"
          :class="{
            'voix-admin-bg voix-admin-text-lightest': open,
            'voix-admin-bg-light voix-admin-text': !open,
          }"
          @click="panelOpen = !panelOpen"
        >
          <div>
            <Icon name="heroicons:chevron-down-20-solid" class="w-5 h-5 transform duration-100" :class="{ 'rotate-180 ': panelOpen }" />
          </div>
        </PopoverButton>
      </div>

      <PopoverOverlay class="fixed z-50 inset-0" />

      <Transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          v-show="panelOpen"
          static
          class="absolute z-50 w-[300px] bg-white rounded-lg voix-admin-text shadow p-3 overflow-hidden"
          :class="{
            'bottom-0 mb-12': direction === 'up',
            'top-0 mt-12': direction === 'down',
          }"
        >
          <Transition
            enter-active-class="transition duration-400 ease-out"
            enter-from-class="translate-x-full opacity-0"
            enter-to-class="translate-x-0 opacity-100"
            leave-from-class="transition duration-400 ease-out"
            leave-active-class="translate-x-0 opacity-100"
            leave-to-class="-translate-x-full opacity-0"
          >
            <div v-show="!saveMode" class="flex flex-col">
              <button
                v-show="props.canDraft"
                class="flex items-center space-x-3 p-2 rounded-lg hover:voix-admin-bg-lightest group"
                @click.prevent.stop="requestAbandonDraft"
              >
                <div
                  class="p-3 rounded voix-admin-bg-lightest voix-admin-text-dark group-hover:text-white group-hover:bg-gray-600 group-hover:translate-x-0.5 transform-all duration-500"
                >
                  <Icon name="heroicons:trash" class="w-5 h-5" />
                </div>
                <div
                  class="text-sm font-medium text-gray-600 group-hover:text-gray-700 tracking-snug"
                >
                  Delete Draft
                </div>
              </button>
              <button
                class="flex items-center space-x-3 p-2 rounded-lg hover:voix-admin-bg-lightest group"
                @click="saveMode = 'queue-new-or-existing-release'"
              >
                <div
                  class="p-3 rounded voix-admin-bg-lightest voix-admin-text-dark group-hover:bg-emerald-200 group-hover:text-emerald-800 group-hover:translate-x-0.5 transform-all duration-500"
                >
                  <Icon name="heroicons:rectangle-stack" class="w-5 h-5" />
                </div>
                <div
                  class="text-sm font-medium text-gray-600 tracking-snug"
                >
                  Save for Later
                </div>
              </button>
              <button
                class="flex items-center space-x-3 p-2 rounded-lg hover:voix-admin-bg-lightest group"
                @click="requestPublish()"
              >
                <div
                  class="p-3 rounded voix-admin-bg-lightest voix-admin-text-dark group-hover:bg-emerald-200 group-hover:text-emerald-800 group-hover:translate-x-0.5 transform-all duration-500"
                >
                  <Icon name="heroicons:fire" class="w-5 h-5" />
                </div>
                <div
                  class="text-sm font-medium text-gray-600 group-hover:text-gray-700 tracking-snug"
                >
                  Save &amp; Publish
                </div>
              </button>
            </div>
          </Transition>

          <!-- Publish Step 2 -->
          <Transition
            enter-active-class="transition duration-400 ease-out"
            enter-from-class="translate-x-full opacity-0"
            enter-to-class="translate-x-0 opacity-100"
            leave-from-class="transition duration-400 ease-out"
            leave-active-class="translate-x-0 opacity-100"
            leave-to-class="-translate-x-full opacity-0"
          >
            <div v-if="saveMode === 'publish'" class="flex flex-col">
              <div>
                <TextInput
                  id="release-label"
                  v-model="releaseLabel"
                  :focus-on-show="true"

                  placeholder="Notes on changes for this release"
                  label=""
                  @keyup.enter.exact.stop.prevent="submitSave(close)"
                />
              </div>
              <div>
                <div class="flex space-x-2 pt-3 pb-1">
                  <span class="text-2xs font-bold uppercase voix-admin-text-dark">Publish Immediately?</span>
                  <CheckboxInput
                    :selected="publishImmediately"
                    @click="publishImmediately = !publishImmediately"
                  />
                </div>
              </div>
              <div v-if="!publishImmediately" class="flex items-center space-x-1">
                <TextInput
                  id="datetime-input"
                  v-model="releaseDate"
                  type="datetime-local"
                  placeholder="Select a date and time for this release"
                  class="flex-1"
                  step="900"
                />

                <button
                  class="px-2 py-1 bg-gray-300 text-gray-600 text-xs rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  @click="releaseDate = null"
                >
                  Clear
                </button>
              </div>
              <div class="flex space-x-1 mt-1.5">
                <button
                  class="px-3 py-1.5 voix-admin-bg text-white text-sm font-medium rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  :disabled="adminStore.isLoading"
                  @click="submitSave(close)"
                >
                  Save
                </button>
                <button
                  class="px-3 py-1.5 bg-gray-300 text-gray-600 text-sm font-medium rounded"
                  @click="endSave(close)"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition>

          <!-- Queue Workflow -->

          <Transition
            enter-active-class="transition duration-400 ease-out"
            enter-from-class="translate-x-full opacity-0"
            enter-to-class="translate-x-0 opacity-100"
            leave-from-class="transition duration-400 ease-out"
            leave-active-class="translate-x-0 opacity-100"
            leave-to-class="-translate-x-full opacity-0"
          >
            <div
              v-show="saveMode === 'queue-new-or-existing-release'"
              class="flex flex-col"
            >
              <button
                class="flex items-center space-x-3 p-2 rounded-lg hover:voix-admin-bg-lightest group"
                @click="saveMode = null"
              >
                <div
                  class="voix-admin-text group-hover:-translate-x-0.5 transform-all duration-500"
                >
                  <Icon name="heroicons:chevron-left" class="w-4 h-4" />
                </div>
                <div
                  class="text-sm font-medium text-gray-600 group-hover:text-gray-700 tracking-snug"
                >
                  Back
                </div>
              </button>
              <button
                class="flex items-center space-x-3 p-2 rounded-lg hover:voix-admin-bg-lightest group"
                @click="saveMode = 'queue-new-release'"
              >
                <div
                  class="p-3 rounded voix-admin-bg-lightest voix-admin-text-dark group-hover:text-white group-hover:bg-gray-600 group-hover:translate-x-0.5 transform-all duration-500"
                >
                  <Icon name="heroicons:sparkles" class="w-5 h-5" />
                </div>
                <div
                  class="text-sm font-medium text-gray-600 group-hover:text-gray-700 tracking-snug"
                >
                  Create a new release
                </div>
              </button>
              <button
                class="flex items-center space-x-3 p-2 rounded-lg hover:voix-admin-bg-lightest group"
                @click="saveMode = 'queue-existing-release'"
              >
                <div
                  class="p-3 rounded voix-admin-bg-lightest voix-admin-text-dark group-hover:text-white group-hover:bg-gray-600 group-hover:translate-x-0.5 transform-all duration-500"
                >
                  <Icon name="heroicons:rectangle-stack" class="w-5 h-5" />
                </div>
                <div
                  class="text-sm font-medium text-gray-600 group-hover:text-gray-700 tracking-snug"
                >
                  Choose an existing release
                </div>
              </button>
            </div>
          </Transition>

          <!-- New Release -->
          <Transition
            enter-active-class="transition duration-400 ease-out"
            enter-from-class="translate-x-full opacity-0"
            enter-to-class="translate-x-0 opacity-100"
            leave-from-class="transition duration-400 ease-out"
            leave-active-class="translate-x-0 opacity-100"
            leave-to-class="-translate-x-full opacity-0"
          >
            <div
              v-if="saveMode === 'queue-new-release'"
              class="flex flex-col"
            >
              <div>
                <TextInput
                  id="release-label"
                  v-model="releaseLabel"
                  :focus-on-show="true"
                  placeholder="Notes on changes for this release"
                  label=""
                  @keyup.enter.exact.stop.prevent="submitSave(close)"
                />
              </div>
              <div class="flex space-x-1 mt-1.5">
                <button
                  class="px-3 py-1.5 voix-admin-bg text-white text-sm font-medium rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  :disabled="!releaseLabel || adminStore.isLoading"
                  @click="submitSave(close)"
                >
                  Save
                </button>
                <button
                  class="px-3 py-1.5 bg-gray-300 text-gray-600 text-sm font-medium rounded"
                  @click="endSave(close)"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition>

          <!-- Existing Release -->
          <Transition
            enter-active-class="transition duration-400 ease-out"
            enter-from-class="translate-x-full opacity-0"
            enter-to-class="translate-x-0 opacity-100"
            leave-from-class="transition duration-400 ease-out"
            leave-active-class="translate-x-0 opacity-100"
            leave-to-class="-translate-x-full opacity-0"
          >
            <div v-if="saveMode === 'queue-existing-release'">
              <ReleaseSelect
                :selected-release-id="releaseId"
                @select-release="selectRelease"
              />

              <div class="mt-4 flex space-x-1">
                <button
                  class="px-3 py-1.5 voix-admin-bg text-white text-sm font-medium rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  :disabled="!releaseId || adminStore.isLoading"
                  @click="submitSave(close)"
                >
                  Save
                </button>
                <button
                  class="px-3 py-1.5 bg-gray-300 text-gray-600 text-sm font-medium rounded"
                  @click="endSave(close)"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition>
        </PopoverPanel>
      </Transition>
    </Popover>

    <VoixModal :show="deleteDraftWarning">
      <div class="bg-white rounded p-4 md:w-[500px] text-base">
        <div class="text-gray-700">
          Are you sure you want to abandon all changes? <span class="font-bold">This cannot be undone!</span>
        </div>
        <div class="mt-4 flex justify-end space-x-3 text-sm">
          <button class="p-2 px-4 rounded bg-gray-200 text-gray-800" @click="deleteDraftWarning = false">
            Cancel, Keep Editing
          </button>
          <button class="p-2 px-4 rounded bg-red-200 text-red-800" @click="deleteDraft">
            Yes, delete current changes
          </button>
        </div>
      </div>
    </VoixModal>
  </div>
</template>
