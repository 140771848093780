<script setup lang="ts">
import { computed, ref } from 'vue'

import { useReleasesStore } from '@voix/store/releasesStore'
import { getReleases } from '@voix/composables/queries/useQueryReleases'
import dayjs from 'dayjs'
import TextInput from '../controls/TextInput.vue'

defineProps({
  selectedReleaseId: {
    type: String,
    required: false,
    default: null,
  },
})
const emit = defineEmits(['selectRelease'])

const filterVersion = ref('')

getReleases({
  filter: JSON.stringify([
    { field: 'status', op: '=', value: 'unscheduled', clause: 'where' },
    { field: 'status', op: '=', value: 'pending', clause: 'orWhere' },
  ]),
  orderBy: [],
  first: 10,
  page: 1,
})

const releases = computed(() => {
  return useReleasesStore().releases
})

const filteredReleases = computed(() => {
  if (filterVersion.value === '') {
    return releases.value.filter((release) => {
      return release.label
        .toLowerCase()
        .includes(filterVersion.value.toLowerCase())
    })
  }
  return releases.value
})

function formatDate(date?: string) {
  if (!date)
    return ''

  return dayjs(date).format('DD/MM/YYYY h:mm a')
}

function requestSelectRelease(release: any) {
  emit('selectRelease', release)
}
</script>

<template>
  <div>
    <div>
      <TextInput
        id="release-save-filter"
        v-model="filterVersion"
        :focus-on-show="true"
        placeholder="Filter by name "
      />
    </div>
    <div class="flex flex-col space-y-4">
      <button
        v-for="(release, key) in filteredReleases"
        :key="key"
        class="relative w-full py-2 px-4 pl-8 text-left text-xs rounded"
        :class="{
          'voix-admin-bg-lightest voix-admin-text': release.id?.toString() === selectedReleaseId,
          'bg-gray-200 text-gray-600': release.id?.toString() !== selectedReleaseId,
        }"
        @click="requestSelectRelease(release)"
      >
        <div class="absolute left-0 top-0 bottom-0 flex items-center -ml-4">
          <div class="bg-white rounded-full p-0.5">
            <svg
              v-if="release.status === 'unscheduled'"
              class="w-7 h-7 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm3 10.5a.75.75 0 000-1.5H9a.75.75 0 000 1.5h6z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-if="release.status === 'published'"
              class="w-7 h-7 text-emerald-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-if="release.status === 'pending'"
              class="w-7 h-7 text-yellow-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-if="release.status === 'cancelled'"
              class="w-7 h-7 text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div>{{ release.label }}</div>
        <div>{{ formatDate(release.release_date) }}</div>
      </button>
    </div>
  </div>
</template>
