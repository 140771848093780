import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import type { Ref } from 'vue'

export function useReleases() {
  dayjs.extend(utc)
  dayjs.extend(timezone)

  const setReleaseData = (releaseSaveInfo: {
    saveMode: Ref<string>
    releaseDate: Ref<string>
    releaseId: Ref<string>
    releaseLabel: Ref<string>
  }, payload: {
    release_date: string
    release_id: string
    release_label: string
  }) => {
    if (releaseSaveInfo.saveMode.value === 'publish') {
      if (releaseSaveInfo.releaseDate.value) {
        // user has set a date
        payload.release_date = dayjs(releaseSaveInfo.releaseDate.value).tz('utc').format('YYYY-MM-DD HH:mm:ss')
      }
      else {
        // user has not set a date. releasing now
        payload.release_date = dayjs().format('YYYY-MM-DD HH:mm:ss')
      }
    }
    else if (
      releaseSaveInfo.saveMode.value === 'queue-new-release'
      && releaseSaveInfo.releaseDate.value
    ) {
      payload.release_date = dayjs(releaseSaveInfo.releaseDate.value).tz('utc').format('YYYY-MM-DD HH:mm:ss')
    }
    else if (
      releaseSaveInfo.saveMode.value === 'queue-existing-release'
      && releaseSaveInfo.releaseId.value
    ) {
      payload.release_id = releaseSaveInfo.releaseId.value
    }

    payload.release_label = releaseSaveInfo.releaseLabel.value

    return payload
  }

  return { setReleaseData }
}
